import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { DateFnsModule } from "ngx-date-fns";
import { QuillModule } from "ngx-quill";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { CardComponent } from "./@core/components/card/card.component";
import { FormsModule } from "@angular/forms";
import { NgxMaskModule } from "ngx-mask";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { PaginaNaoEncontradaComponent } from "./@core/components/pagina-nao-encontrada/pagina-nao-encontrada.component";
import { ButtonModule } from "primeng/button";

import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { MessageService } from "primeng/api";
import { CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
  declarations: [AppComponent, CardComponent, PaginaNaoEncontradaComponent],
  bootstrap:[AppComponent],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    DateFnsModule,
    QuillModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false,
    }),
    BrowserAnimationsModule,
    ButtonModule,
    MessagesModule,
    
  ],
  providers: [MessageService],
})
export class AppModule {}
