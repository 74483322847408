import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./@core/guard/auth.guard";
import { PaginaNaoEncontradaComponent } from "./@core/components/pagina-nao-encontrada/pagina-nao-encontrada.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./@core/components/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "seguranca",
    loadChildren: () =>
      import("./pages/seguranca/seguranca.module").then(
        (module) => module.SegurancaModule
      ),
  },
  // {
  //   path: "painel",
  //   loadChildren: () =>
  //     import("./pages/painel/painel.module").then(
  //       (module) => module.PainelModule
  //     ),
  // },
  {
    path: "admin-module",
    loadChildren: () =>
      import("./admin-module/admin-module.module").then(
        (module) => module.AdminModuleModule
      ),
  },
  {
    path: "agenda",
    loadChildren: () =>
      import("./pages/agenda/agenda.module").then(
        (module) => module.AgendaModule
      ),
  },
 
  {
    path: "consultorio",
    loadChildren: () =>
      import("./pages/consultorio/consultorio.module").then(
        (module) => module.ConsultorioModule
      ),
    // canActivateChild: [AuthGuard],
  },
  {
    path: "cadastro",
    loadChildren: () =>
      import("./pages/cadastro/cadastro.module").then(
        (module) => module.CadastroModule
      ),
    // canActivateChild: [AuthGuard],
  },
  {
    path: "planos",
    loadChildren: () =>
      import("./pages/planos/planos.module").then(
        (module) => module.PlanosModule
      ),
  },
  {
    path: "configuracoes",
    loadChildren: () =>
      import("./pages/configuracoes/configuracoes.module").then(
        (module) => module.ConfiguracoesModule
      ),
    // canActivateChild: [AuthGuard],
  },
  {
    path: "financeiro",
    loadChildren: () =>
      import("./pages/financeiro/financeiro.module").then(
        (module) => module.FinanceiroModule
      ),
    // canActivateChild: [AuthGuard],
  },


  {
    path: "faturamento",
    loadChildren: () =>
      import("./pages/faturamento/faturamento.module").then(
        (module) => module.FaturamentoModule
      ),
    // canActivateChild: [AuthGuard],
  },
  {
    path: "novo-cliente/:planId",
    loadChildren: () =>
      import("./pages/novo-cliente/clients.module").then(
        (module) => module.ClientsModule
      ),
  },
  {
    path: "**",
    component: PaginaNaoEncontradaComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
